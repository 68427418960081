<template>
    <div>
        <validation-observer ref="createLessonForm">
            <b-form>
                <b-modal
                    dialog-class="create-new-lesson-modal-dialog"
                    content-class="create-new-lesson-modal-content"
                    v-model="isCreateLessoneDialogLessonVariable"
                    scrollable
                    size="sm"
                    @hide="toggleCreateLessonDialog(false)"
                >
                    <template #modal-title>
                        <strong>إضافة درس</strong>
                    </template>
                    <b-form-group label="اسم الدرس" label-for="lessonName">
                        <validation-provider
                            #default="{ errors }"
                            name="اسم الدرس"
                            rules="required"
                        >
                            <b-form-input
                                type="text"
                                id="lessonName"
                                :state="errors.length > 0 ? false : null"
                                placeholder="اسم الدرس"
                                v-model="lessonDto.name"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="تابع للوحدة" label-for="followUnit" >
                        <validation-provider
                            #default="{ errors }"
                            name="الوحدة"
                            rules="required"
                        >
                            <v-select
                                id="followUnit"
                                v-model="lessonDto.unitId"
                                :reduce="option => option.id"
                                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                                label="name"
                                :options="unitsList"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="الترتيب" label-for="lessonOrder">
                        <validation-provider
                            #default="{ errors }"
                            name="الترتيب"
                            rules="required|positive"
                        >
                            <b-form-input
                                type="number"
                                id="lessonOrder"
                                :state="errors.length > 0 ? false : null"
                                v-model="lessonDto.lessonOrder"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <template #modal-footer>
                        <b-button
                            variant="primary"
                            size="md"
                            type="submit"
                            @click.prevent="createLessonValidationForm"
                        >
                            إضافة
                        </b-button>
                        <b-button
                            @click="toggleCreateLessonDialog(false)"
                            size="md"
                            variant="outline-primary"
                        >
                            تراجع
                        </b-button>
                    </template>
                </b-modal>
            </b-form>
        </validation-observer>
    </div>
</template>

<style lang="scss">
.create-new-lesson-modal-dialog {
    margin: 0 !important;
    max-height: 100vh !important;
    height: 100vh;
}
.create-new-lesson-modal-content {
    max-height: 100vh !important;
    border-radius: 0 !important;
}
</style>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import { mapActions, mapGetters } from "vuex";
import { BButton, BForm, BFormGroup, BFormInput } from "bootstrap-vue";
import vSelect from 'vue-select';
import { required, positive } from '@validations';

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BButton,
        BFormGroup,
        BFormInput,
        BForm,
        vSelect
    },
    data: () => ({
        required,
        positive,
        isCreateLessoneDialogLessonVariable: false,
    }),
    created(){
        localize('ar')
    },
    computed: {
        ...mapGetters(["isCreateDialog", "unitsList", "lessonDto"])
    },
    mounted() {
        this.getUnitsList()
    },
    methods: {
        ...mapActions(["toggleCreateLessonDialog", "createLesson", "getUnitsList"]),
        createLessonValidationForm() {  
            this.$refs.createLessonForm.validate()
            .then(success => {
                 if(success) {
                    this.createLesson(this.lessonDto).then(() => {
                        Object.assign(this.lessonDto, {
                            id: 0,
                            name: '',
                            lessonOrder: 0,
                            unitId: null,
                            unitName: '',
                        })
                        requestAnimationFrame(() => {
                            this.$refs.createLessonForm.reset();
                        });
                    })
                }
            })
        },
    },
    watch: {
        isCreateDialog(newVal) {
            this.isCreateLessoneDialogLessonVariable = newVal
        }
    }
};
</script>
