<template>
  <div>
    <b-row v-if="mainLissonList.length > 0">
      <b-col
        cols="12"
        md="6"
        lg="3"
        v-for="(lesson, index) in lessonsActivePage"
        :key="index"
      >
        <b-card img-top style="max-width: 20rem" class="mb-3 mx-auto" no-body>
          <b-card-img
            src="/media/placeholder/lesson-placeholder.png"
            class="w-100"
            style="border-radius: 0 !important; object-fit: fill; height: 200px"
          ></b-card-img>
          <b-button-group>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="ticket" class="pr-1" fill="#5E5873" width="18"></unicon>
              ({{ lesson.subscriptionsCount }})
            </b-button>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
              :style="`color : ${lesson.hasUnAnsweredQuestions == true ? '#fcb26a' : '#5E5873'}`"
            >
              <unicon
                name="comment-alt-dots"
                class="pr-1"
                :fill="lesson.hasUnAnsweredQuestions == true ? '#fcb26a' : '#5E5873'"
                width="18"
              ></unicon>
              ({{ lesson.studentsQuestionsCount }})
            </b-button>
            <b-button
              size="sm"
              variant="btn-flat"
              class="d-flex align-items-center justify-content-center"
            >
              <unicon name="video" class="pr-1" fill="#5E5873" width="18"></unicon>
              ({{ lesson.videosCount }})
            </b-button>
          </b-button-group>
          <b-card-header class="py-1">
            <strong>{{ lesson.name }}</strong>
          </b-card-header>
          <b-card-body class="pt-0">
            <b-card-text>
              {{ lesson.unitName }}
            </b-card-text>
          </b-card-body>

          <b-button
            href="#"
            size="md"
            variant="primary"
            style="border-radius: 0 0 0.428rem 0.428rem !important"
            :to="`/lessons/${lesson.id}/0`"
          >
            عرض التفاصيل
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <b-pagination
          :value="lessonsFirstPage"
          :total-rows="mainLissonList.length"
          :per-page="pageLength"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="(value) => updateLessonPagination(value)"
        >
          <template #prev-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
          <template #next-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row v-else class="justify-content-center mt-2">
      <h4>لا يوجد دروس متاحة</h4>
    </b-row>
    <createDialog />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import {
  BPagination,
  BCard,
  BCardText,
  BButtonGroup,
  BButton,
  BCardImg,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
} from "bootstrap-vue";
import createDialog from "./create-dialog.vue";

export default {
  components: {
    createDialog,
    BPagination,
    BCard,
    BCardText,
    BButtonGroup,
    BButton,
    BCardImg,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["mainLissonList", "lessonsActivePage", "pageLength", "lessonsFirstPage","lessonDto"]),
  },
  mounted() {
    if(this.lessonDto.unitId){
        this.toggleCreateLessonDialog(true)
    }
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      text: "درس جديد",
      fetchingFunction: this.toggleCreateLessonDialog,
      placeHolder: "ابحث عن درس محدد",
      value: "",
      filterFunc: this.filterLessons,
    });
    this.getLessons(this.index);
  },
  created(){
    console.log(this.lessonDto.unitId)
    
  },
  methods: {
    ...mapActions([
      "toggleCreateLessonDialog",
      "getLessons",
      "pageChanged",
      "updateLessonPagination",
      "filterLessons",
      "fetchFilteredLessons"
    ]),
  },
  watch: {
    index(val){

      this.fetchFilteredLessons(val)

      store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      text: "درس جديد",
      fetchingFunction: this.toggleCreateLessonDialog,
      placeHolder: "ابحث عن درس محدد",
      value: "",
      filterFunc: this.filterLessons,
    });
    }
  },
  props:{
    index: String
  }
};
</script>
